import React from 'react';

// import sections
import GenericSection from '../components/sections/GenericSection';
import Cta from '../components/sections/Cta';

class TermsOfService extends React.Component {
    render() {
        return (
        <React.Fragment>
            <GenericSection topDivider>
            <div className="container-xs">
                <h2 className="mt-0">Terms of Service</h2>

                <p>
                    Last updated: July 30th, 2022
                </p>

                <h4>Introduction</h4>
                <p>
                    Aloe Health, Inc. ("Company", “we”, or “us”) develops, operates and distributes certain software applications (Apps), which include web apps and hosted applications. The Apps, together with the services for communications, coordination and management by patients and healthcare professionals regarding medical conditions, and the data collection, storage, analysis and reporting tools, functions and services, that are provided via the Apps, are collectively referred to as the “Service” or “Services”. The Service is offered and provided subject to acceptance of all of the terms and conditions contained herein (“Terms of Service”). The Terms of Service shall be deemed to include all other operating rules, conditions, policies and procedures that are referred to within this document or that may be published by Company on their website or via any App (collectively, Policies), including without limitation, the Privacy Policy.

                </p>
                <p>
                    These Terms of Service are entered into by and between You and the Company. The following terms and conditions, together with any documents they expressly incorporate by reference (collectively, "Terms of Service"), govern your access to and use of our Services whether as a guest or a registered user.
                </p>
                <p><b>
                    Please read the Terms of Service carefully before you start to use the Service. By using the Service or by clicking to accept or agree to the Terms of Service when this option is made available to you, you accept and agree to be bound and abide by these Terms of Service and our Privacy Policy, found at https://www.aloehealth.ca/privacy-policy/, incorporated herein by reference. If you do not want to agree to these Terms of Service or the Privacy Policy, you must not access or use the Service.
                </b></p>

                <p>
                    This Service is offered and available to users who are 18 years of age or older and reside in Canada or at least 13 years of age who have the express permission of their parent or guardian. By using this Service, you represent and warrant that you are of legal age to form a binding contract with the Company and meet all of the foregoing eligibility requirements. If you do not meet all of these requirements, you must not access or use the Service.
                </p>

                <p>
                    The Service is intended by Company to be made available only to:
                </p>
                <ul>
                    <li>Clinicians,</li>
                    <li>Patients who have been specifically identified for use of the Service by a Clinical Partner.</li>
                </ul>

                <p>
                    As used in the Terms of Service: 
                </p>
                <ul>
                    <li>"Clinical Partners” are hospitals, clinics, practices or other medical groups or healthcare systems that have contracted with Company to permit use of the Service by their respective Clinicians and Patients.</li>
                    <li>“Clinicians” can be any of the following:
                        <ul>
                            <li>Practitioners,</li>
                            <li>Patient Advocates,</li>
                            <li>Employees of or contractors to a Clinical Partner who provide healthcare services to Patients.</li>
                        </ul>
                    </li>
                    <li>“Patients” are individual patients of the Clinical Partner who receive medical treatments or other healthcare services from one or more Clinicians.</li>
                </ul>

                <p><b>If you do not qualify, then you are prohibited from downloading, accessing, using and registering for the Service.</b></p>
                <p>
                    The Clinical Partner, not Company, is solely responsible for establishing the criteria and determining which of its clinicians and patients will be (and continue to be) eligible to use the Service. Without limiting the foregoing, the Clinical Partner is solely responsible for determining whether the Service will be (and continues to be) clinically appropriate and safe for each Patient. Company will comply with any instruction from a Clinical Partner to discontinue offering the Service to any of its Clinicians or Patients. In addition, Company may refuse to offer or continue offering the Service to any person and may change its eligibility criteria from time to time.
                </p>

                <h4>
                    Changes to the Terms of Service 
                </h4>

                <p>
                    We may revise and update these Terms of Service from time to time in our sole discretion. All changes are effective immediately when we post them. However, any changes to the dispute resolution provisions set forth in Governing Law and Jurisdiction will not apply to any disputes for which the parties have actual notice on or prior to the date the change is posted on the Service.
                </p>
                <p>
                    Your continued use of the Service following the posting of revised Terms of Service means that you accept and agree to the changes. You are expected to check this page from time to time so you are aware of any changes, as they are binding on you.
                </p>

                <h4>Accessing the Service and Account Security</h4>
                <p>
                    We reserve the right to withdraw or amend this Service, and any service or material we provide on the Service, in our sole discretion without notice. We will not be liable if for any reason all or any part of the Service is unavailable at any time or for any period. From time to time, we may restrict access to some parts of the Service, or the entire Service, to users, including registered users.
                </p>
                <p>You are responsible for:</p>
                <ul>
                    <li>Making all arrangements necessary for you to have access to the Service.</li>
                    <li>Ensuring that all persons who access the Service through your internet connection are aware of and comply to these Terms of Service.</li>
                </ul>
                <p>
                    To access the Service or some of the resources it offers, you may be asked to provide certain registration details or other information. It is a condition of your use of the Service that all the information you provide on the Service is correct, current and complete. You agree that all information you provide to register with this Service or otherwise, including, but not limited to, through the use of any interactive features on the Service, is governed by our <a href="https://www.aloehealth.ca/privacy-policy/">Privacy Policy</a>, and you consent to all actions we take with respect to your information consistent with our Privacy Policy.
                </p>
                <p>
                    If you choose, or are provided with, a user name, password or any other piece of information as part of our security procedures, you must treat such information as confidential, and you must not disclose it to any other person or entity. You also acknowledge that your account is personal to you and agree not to provide any other person with access to this Service or portions of it using your user name, password or other security information. You agree to notify us immediately of any unauthorized access to or use of your user name or password or any other breach of security. You also agree to ensure that you exit from your account at the end of each session. You should use particular caution when accessing your account from a public or shared computer so that others are not able to view or record your password or other personal information.
                </p>
                <p>
                    We have the right to disable any user name, password or other identifier, whether chosen by you or provided by us, at any time in our sole discretion for any or no reason, including if, in our opinion, you have violated any provision of these Terms of Service.
                </p>

                <h4>Services do not include Healthcare Services</h4>
                <p>
                    Company does not offer or provide any kind of medical advice, health insurance or other healthcare service, including without limitation, any counseling, testing, medication, procedure or therapy related to the avoidance, prevention, diagnosis or treatment of any acute or chronic illness, disease or condition (collectively, Healthcare Services). The Service is not a substitute for professional Healthcare Services. Always seek the advice of your physician or other qualified healthcare provider with questions regarding any medical condition, or call 911 in an emergency. The Service is intended only as a tool, which can be used to assist Patients and Clinicians in coordinating, managing and communicating about routine Healthcare Services. The Service is not intended for use in any emergency or urgent matter. As a result of its limited role as an intermediary between Patients and Clinicians, Company has no control over the appropriateness, accuracy, quality, timeliness, scope, price or risks of or associated with any Healthcare Service. Therefore, you agree to release Company from any and all claims, demands, damages and liabilities, of any type and nature, known and unknown, suspected and unsuspected, disclosed and undisclosed, arising out of or in any way connected with Healthcare Services (including any injuries or disability arising therefrom). To the extent that any Healthcare Services are offered to any Patient, they are provided by the applicable Clinical Partner and its affiliated Clinicians, not Company. Accordingly, Healthcare Services are not deemed to be within the scope of the Service provided by Company, and Company shall have no obligation or liability regarding Healthcare Services. Company does not recommend or endorse any specific Clinical Partner, Clinician or Healthcare Service.
                </p>
                
                <h4>Information About You and Your Visits to the Service</h4>
                <p>
                    All information we collect on this Service is subject to our Privacy Policy. By using the Service, you consent to all actions taken by us with respect to your information in compliance with the Privacy Policy.
                </p>

                <h4>Disclaimer of Warranties</h4>
                <p>
                    You understand that we cannot and do not guarantee or warrant that files available for downloading from the internet or the Service will be free of viruses or other destructive code. You are responsible for implementing sufficient procedures and checkpoints to satisfy your particular requirements for anti-virus protection and accuracy of data input and output, and for maintaining a means external to our site for any reconstruction of any lost data. WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE SERVICE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICE OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON ANY SERVICE LINKED TO IT.
                </p>
                <p>
                    YOUR USE OF THE SERVICE, ITS CONTENT AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICE IS AT YOUR OWN RISK. THE SERVICE, ITS CONTENT AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICE ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER THE COMPANY NOR ANY PERSON ASSOCIATED WITH THE COMPANY MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY OR AVAILABILITY OF THE SERVICE. WITHOUT LIMITING THE FOREGOING, NEITHER THE COMPANY NOR ANYONE ASSOCIATED WITH THE COMPANY REPRESENTS OR WARRANTS THAT THE SERVICE, ITS CONTENT OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICE WILL BE ACCURATE, RELIABLE, ERROR-FREE OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT OUR SITE OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE SERVICE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICE WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.
                </p>
                <p>
                    THE COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT AND FITNESS FOR PARTICULAR PURPOSE.
                </p>
                <p>
                    THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
                </p>

                <h4>Limitation on Liability</h4>
                <p>
                    TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT WILL THE COMPANY, ITS AFFILIATES OR THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE SERVICE, ANY SERVICES LINKED TO IT, ANY CONTENT ON THE SERVICE OR SUCH OTHER SERVICES OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICE OR SUCH OTHER SERVICES, INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT OR OTHERWISE, EVEN IF FORESEEABLE.
                </p>
                <p>
                THE FOREGOING DOES NOT AFFECT ANY LIABILITY WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
                </p>

                <h4>Indemnification</h4>
                <p>
                    You agree to defend, indemnify and hold harmless the Company, its affiliates, licensors and service providers, and its and their respective officers, directors, employees, contractors, agents, licensors, suppliers, successors and assigns from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses or fees (including reasonable attorneys' fees) arising out of or relating to your violation of these Terms of Service or your use of the Service, including, but not limited to, your User Contributions, any use of the Service's content, services and products other than as expressly authorized in these Terms of Service, or your use of any information obtained from the Service.
                </p>

                <h4>Limitation on Time to File Claims</h4>
                <p>
                    ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO THESE TERMS OF SERVICE OR THE SERVICE MUST BE COMMENCED WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES; OTHERWISE, SUCH CAUSE OF ACTION OR CLAIM IS PERMANENTLY BARRED.
                </p>

                <h4>Waiver and Severability</h4>
                <p>
                    No waiver of by the Company of any term or condition set forth in these Terms of Service shall be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure of the Company to assert a right or provision under these Terms of Service shall not constitute a waiver of such right or provision.
                </p>
                <p>
                    If any provision of these Terms of Service is held by a court or other tribunal of competent jurisdiction to be invalid, illegal or unenforceable for any reason, such provision shall be eliminated or limited to the minimum extent such that the remaining provisions of the Terms of Service will continue in full force and effect.
                </p>

                <h4>Entire Agreement</h4>
                <p>
                    The Terms of Service and our Privacy Policy, which are all hereby incorporated by reference, constitute the sole and entire agreement between you and AloeHealth, Inc. with respect to the Service and supersede all prior and contemporaneous understandings, agreements, representations and warranties, both written and oral, with respect to the Service.
                </p>

                <h4>Feedback and Concerns</h4>
                <p>
                    This Service is operated by Aloe Health, Inc 290 Bremner Blvd, Toronto, ON, M5V 3L9, Canada.
                </p>
                <p>
                    All other feedback, comments, requests for technical support and other communications relating to the Service should be directed to: contact@aloehealth.ca
                </p>
            </div>
            </GenericSection>

            <div className="spacer-64 spacer-24-mobile has-bg-color"></div>
            <div className="spacer-24 has-bg-color"></div>        
        </React.Fragment>
        );
    }
}

export default TermsOfService;