import React from 'react';
import { withRouter, Switch } from 'react-router-dom';
import AppRoute from './utils/AppRoute';
import ScrollReveal from './utils/ScrollReveal';

// Layouts
import LayoutDefault from './layouts/LayoutDefault';

// Views 
import Home from './views/Home';
import PrivacyPolicy from './views/PrivacyPolicy';
import TermsOfService from './views/TermsOfService';

class App extends React.Component {

  constructor() {
    super();
    this.scrollReveal= React.createRef();
  }

  componentDidMount() {
    document.body.classList.add('is-loaded')
    this.scrollReveal.current.init();
  }

  // Route change
  componentDidUpdate(prevProps) {
    window.scrollTo(0, 0)
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.scrollReveal.current.init();
    }
  }

  render() {
    return (
      <ScrollReveal
        ref={this.scrollReveal}
        children={() => (
          <Switch>
            <AppRoute exact path="/" component={Home} layout={LayoutDefault} />
            <AppRoute exact path="/privacy-policy" component={PrivacyPolicy} layout={LayoutDefault} />
            <AppRoute exact path="/terms-of-service" component={TermsOfService} layout={LayoutDefault} />
          </Switch>
        )} />
    );
  }
}

export default withRouter(props => <App {...props} />);