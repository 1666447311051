import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Image from '../../elements/Image';

const Logo = ({
  className,
  altLogo,
  ...props
}) => {

  const classes = classNames(
    'brand',
    className
  );

  return (
    <div
      {...props}
      className={classes}
    >
      <h1 className="m-0">
        <Link to="/">
          <Image
            src={require( altLogo ? './../../../assets/images/logo_new_alt.svg' : './../../../assets/images/logo_new.svg')}
            alt="Logo"
            width={150}
            height={100} />
        </Link>
        {/* <div className="m-0-text">
          Aloe Health
        </div> */}
      </h1>
    </div>
  );
}

export default Logo;