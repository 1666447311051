import React from 'react';

// import sections
import GenericSection from '../components/sections/GenericSection';
import Cta from '../components/sections/Cta';

class PrivacyPolicy extends React.Component {
    render() {
        return (
        <React.Fragment>
            <GenericSection topDivider>
            <div className="container-xs">
                <h2 className="mt-0">Privacy Policy</h2>

                <p>
                    Last updated: August 6th, 2022
                </p>

                <h4>Introduction</h4>
                <p>
                    Aloe Health,  Inc. ("We" or “Our”) respect your privacy and are committed to protecting it through our compliance with this policy. This Privacy Policy applies to personal information collected by Clinical Partners that utilize our Software-As-A-Service (SaaS) solutions.
                </p>
                <p>
                    This document aims to:
                </p>
                <ul>
                    <li>outline the type of personal information collected,</li>
                    <li>express how our service uses that information in compliance with applicable laws and ethical business practices,</li>
                    <li>highlight the safeguards we have in place to protect that information.</li>
                </ul>
                <p>
                    Aloe Health's privacy policies and guidelines are guided by both Ontario's Personal Health Information Protection Act ("PHIPA") and the federal Personal Information Protection and Electronic Documents Act ("PIPEDA").
                </p>
                <p>
                    Aloe Health supports clinicians in the distribution and analysis of medical questionnaires. Aloe Health has created solutions that allow for easy and secure access for patients to record their submissions, requiring the use of patient health information (PHI).
                </p>




                <h4>What personal information does Aloe Health collect?</h4>
                <p>
                    Personal information includes information about an identifiable individual.  Personal information does not include name, business address, or business contact information, provided for business purposes.             </p>
                <p>
                    Aloe Health’s Medical Questionnaire Service currently collects:
                </p>
                <ul>
                    <li>health card numbers,</li>
                    <li>names,</li>
                    <li>emails,</li>
                    <li>phone numbers.</li>
                </ul>
                <p>
                    Aloe Health itself does not collect or store the personal health information of patients for its own purposes.
                </p>



                <h4>How is personal information used?</h4>
                <p>
                    Aloe Health’s Medical Questionnaire service collects, uses, and discloses PHI on behalf of health information custodians to leverage and adopt new information technology.
                </p>
                <p>
                    Our Medical Questionnaire service uses personal information for the following purposes: 
                </p>
                <ul>
                    <li>Registering physicians and other health care clinicians or their administrators so that they can use the Aloe Health Clinic Portal (clinic.aloehealth.ca)</li>
                    <li>Providing secure and easy mobile login for patients to submit questionnaires</li>
                    <li>Creating patient profiles to outline and analyze their historical record</li>
                </ul>



                <h4>How long is your information stored?</h4>
                <p>
                    We will only keep your personal information as long as it is necessary for the purposes set out in this privacy notice, unless a longer retiention period is required or permitted by law (such as tax, accounting or other legal requirements). 
                </p>
                <p>
                    When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information, or, if this is not possible, then we will securely store your personal inforamtion and isolate it from further processing until deletion is possible.
                </p>
                



                <h4>When and with whom do we share your personal information?</h4>
                <p>
                    We may need to share your personal information in connection with, or, during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.
                </p>



                <h4>Consent</h4>
                <p>
                    By providing personal information to our Clinical Partners through one of our Services, you consent to our collection, use, or disclosure of such personal information in accordance with this Privacy Policy and as permitted or required by applicable laws and ethical business practices. An exception is made in cases of legal, medical, or security reasons where it is impossible or impractical to receive consent.
                </p>
                <p>
                    You have the right to withdraw your consent at any time. You can withdraw your consent at any time by contacting us at privacy@aloehealth.ca. However, please note that this will not affect the lawfulness of the processing before its withdrawal nor, when applicable law allows, will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.
                </p>



                <h4>Security</h4>
                <p>
                    Your personal information is treated as private and confidential information by Aloe Health.  We strive to ensure that your personal information is protected and kept secure by employing various security safeguards. Aloe Health only keeps personal information for as long as it is required for legal or business purposes. Although we make every reasonable effort to protect your personal information from unauthorized access, release, use, loss and theft, disclosure, alteration by third parties, copying or modification by physical and logical security procedures, confidentiality policies, and authorization requirements, you should be aware there is always some risk involved in transmitting information over the Internet. As a result, Aloe Health does not represent, warrant or guarantee that personal information will be protected against loss, misuse or alteration and does not accept any liability for personal information submitted by you, nor for your or third parties' use or misuse of personal information.
                </p>


                <h4>Changes to this privacy policy</h4>
                <p>
                    Aloe Health reserves the right to modify or amend this Privacy Policy from time to time, and we encourage you to refer back to this policy regularly. The date of the most recent version is posted at the top of the Privacy Policy. If any material changes occur, we will notify you and ask for consent, if necessary.
                </p>


                <h4>Accessing your personal information</h4>
                <p>
                    You have the right to verify and amend your personal information collected by our services. You are also free to withdraw your consent to such collection, use, and disclosure of your personal information. On written request, and in a reasonable timeframe, you have the right to access your personal information, identify the uses to which that information is put, and identify any third party to whom it may have been disclosed, and for what purpose. These rights are not absolute; however, if we deny your request for access, we will provide clear reasons for doing so.
                </p>
                <p>
                    Incorrect information identified by an individual can be deleted or updated at the individual’s request.
                </p>


                <h4>Safeguards</h4>
                <p>
                    Aloe Health’s services maintain reasonable and appropriate administrative, technical and physical safeguards to protect against unauthorized access, use, modification, and disclosure of personal information. PHI will only be disclosed to authorized persons who require such information for the purposes mentioned above.
                </p>


                <h4>Contact us</h4>
                <p>
                    If you have any questions or concerns about how Aloe Health manages your personal information and protects your privacy, please contact our <b>General Counsel and Chief Privacy Officer</b> at:
                </p>
                <ul>
                    {/* <li><b>Address: </b> Test</li>
                    <li><b>Phone: </b> Test</li> */}
                    <li><b>Email: </b> privacy@aloehealth.ca</li>
                </ul>
                <p>
                    You have the right to complain to the Information and Privacy Commissioner of Ontario or the Office of the Privacy Commissioner of Canada if you are concerned that Aloe Health has violated privacy obligations regarding personal health information or personal information.
                </p>


                <h4>Information and Privacy Commissioner of Ontario</h4>
                <ul>
                    <li><b>Address: </b> 2 Bloor Street East, Suite 1400 Toronto, ON M4W 1A8</li>
                    <li><b>Phone: </b> 1-800-387-0073</li>
                    <li><b>Phone: </b> 416-326-3333</li>
                    <li><b>Fax: </b> 416-325-9195</li>
                    <li><b>Web: </b> https://www.ipc.on.ca</li>
                </ul>


                <h4>Office of the Privacy Commissioner of Canada</h4>
                <ul>
                    <li><b>Address: </b> 30 Victoria Street Gatineau, Quebec K1A 1H3</li>
                    <li><b>Phone: </b> 1-800-282-1376</li>
                    <li><b>Phone: </b> 819-994-5444</li>
                    <li><b>Fax: </b> 819-994-5424</li>
                    <li><b>Web: </b> https://priv.gc.ca</li>
                </ul>

            </div>
            </GenericSection>

            <div className="spacer-64 spacer-24-mobile has-bg-color"></div>
            <div className="spacer-24 has-bg-color"></div>        
            {/* <Cta hasBgColor invertColor topDivider bottomDivider split /> */}
        </React.Fragment>
        );
    }
}

export default PrivacyPolicy;